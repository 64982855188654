import React from "react"

import SEO from "../components/seo"

const NotFoundPage = () => (
  <div>
    <SEO title="404: Not found" />
    <div className="page404">
      <h1>What are you find.</h1>
      <p>E-mail me: skillinlin@gmail.com</p>
    </div>
  </div>
)

export default NotFoundPage
